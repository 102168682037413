import { isFuture } from 'date-fns'
import {
  APIRedemptions,
  Redemptions,
} from '@mindfulchefuk/features/Subscription/interfaces'

export const transformRedemptions = (
  redemptions: APIRedemptions[]
): Redemptions =>
  redemptions
    .filter(({ promotion }) => isFuture(new Date(promotion.valid_until)))
    .map(({ promotion }) => {
      const { channel, banner_description: description } = promotion

      return {
        description,
        type: channel.name,
      }
    })
