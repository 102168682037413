import React, { ReactElement } from 'react'
import { Text } from '@mindfulchefuk/design-system'
import { format } from 'date-fns'

export const Copyright = (): ReactElement => (
  <Text as="p" maxWidth={{ md: 128 }} variant="body-sm">
    &copy; Copyright
    {` ${format(Date.now(), 'yyyy')} `}
    Mindful Chef Ltd. All rights reserved
  </Text>
)
